.container {
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #FFFFFF;
    border-radius: 10px;
    max-width: 101px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
  }
  
  .clock-container {
    display: flex;
    flex-direction: column;
  }
  
  .clock {
    width: 101px;
    height: 101px;
    border-radius: 10px;
    background-color: #90695a;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
  }
  
  .clock.active {
    background-color: #AB2A3B;
  }
  
  #secondClock {
    margin-top: 290px;
  }

  .player-label {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .time {
    font-size: 20px;
    font-weight: bold;
  }

  