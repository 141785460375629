.main-page {
    display: flex;
    justify-content: center;
    max-width: 100vw;
}

.course-list {
    width: 302px;
    height: 268px;
    -webkit-box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.75);
    border-radius: 20px;
    margin-top: 132px
}

.podpiska {
    display: flex;
    flex-direction: column;
    width: 302px;
    height: 268px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #FFFFFF;
}

.podpiska-item:hover  {
    cursor: pointer;
    background-color: #ab2a3b8a;
    opacity: 0.7;
    transition: 0.1s;
}   

.podpiska-item:hover .podpiska-text {
    color: white;
}   

.podpiska-item {
    display: inline-flex;
    align-items: center;
    width: 302px;
    height: 67px;
}

#allCourses {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

#firstPodpiska {
    border-top:1px solid #AB2A3B;
    border-bottom:1px solid #AB2A3B;
}

#thirdPodpiska {
    border-top:1px solid #AB2A3B;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.podpiska-text {
    position: relative;
    font-family: 'Open Sans';font-size: 18px;font-weight: 350;color: #000000;
    margin-left: 30px;
    width: 235px;
}

.podpiskaImg {
    width: 15px;
    margin-left: 5px;
}

.setOn .podpiskaImg {
    margin-left: 0;
    width: 24px;
    transform: rotate(-90deg);
}

.setOn {
    background-color: #AB2A3B !important;
}

.setOn .podpiska-text {
    color: #FFFFFF;
}

.game-list {
    width: 302px;
    height: 268px;
    -webkit-box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.75);
    border-radius: 20px;
    background-color: #FFFFFF;
    margin-top: 50px
}

.game-type {
    display: flex;
    width: 302px;
    height: 66.3px;
    background-color: #ffffff ;
    border-bottom:1px solid #AB2A3B;
    align-items: center;
    cursor: pointer;
}

.game-type:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.game-type:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom: none;
}

.game-type:hover {
    cursor: pointer;
    background-color: #ab2a3b8a;
    opacity: 0.7;
    transition: 0.1s;
}

.game-type:hover .podpiska-text {
    color: white;
}   

.lists-wrapper {
    opacity: 0;
    transform: translateY(20px); /* Shift them down slightly */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; /* Smooth transition */
}

.lists-wrapper.visible {
    opacity: 1;
    transform: translateY(0); /* Move them to their normal position */
}

.main-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 132px;
    width: 870px; 
    margin-left: 5%;
    margin-bottom: 5%;
    height: fit-content;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.75);
    border-radius: 20px;
    opacity: 0;
    transform: translateY(20px); /* Shift them down slightly */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; /* Smooth transition */
}

.main-panel.visible {
    opacity: 1;
    transform: translateY(0); /* Move them to their normal position */
}

.main-panel-content {
    width: 100%;
    margin-left: 65px;
    margin-top: 20px;
    margin-bottom: 25px;
}

.levelName {
    width: fit-content;
    font-size: 20px;font-weight: 550;color: #AB2A3B;
}

.pro-level {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
}

.theme-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 245px;
    min-height: 250px;
    height: fit-content;
    margin-right: 30px;
    bottom: 20px;
    transition: transform 0.3s ease;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    opacity: 0;
    transform: translateY(20px); /* Shift them down slightly */
    transition: opacity 0.3s ease-out, transform 0.3s ease-out; /* Smooth transition */
}

.theme-block.visible {
    opacity: 1;
    transform: translateY(0); /* Move them to their normal position */
}

.theme-block:hover{
    cursor: pointer;
    transform: translateY(-10px);
}

.theme-content {
    background-color: rgb(255, 255, 255);
    box-shadow: 1px 1px 3px 3px rgba(71, 71, 71, 0.212);
    border-radius: 4%;
    width: 250px;   
    min-height: 170px;
    height: fit-content;
    z-index: 2;
}

.theme-text {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.themeImg {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    top: 37px;
    width: 200px;
    height: 200px;
    border-radius: 30px;
    overflow: hidden;
}

.hidden {
    visibility: hidden;
}

.themeImgSize {
    width: 100%;
}

.themeImgPoloska {
    position: relative;
    bottom: 47.5px;
    width: 105px;
    height: 26px;
    background-color: #AB2A3B;
}

.theme-name {
    margin-top: 20px;
    min-height: 55px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 20px;font-weight: normal;color: #282323;
}

.theme-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    margin-top: 5px;
    height: 30px;
    font-size: 16px;font-weight: lighter;
}

.theme-info span{
    color: #282323;
    width: 80px;
}

.theme-info li{
    color: red;
    margin-right: 10px;
}

.theme-content-percent {
    margin-top: 5px;
    font-size: 20px;font-weight: normal;color: black;
}

.theme-content-percent span {
    color: #AB2A3B;
}

@media screen and (max-width: 1200px) {
    
    .lists-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .game-list {
        width: 50%;
        height: 53px;
        -webkit-box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.75);
        border-radius: 20px;
        margin-top: 20px;
        display: flex;
    }

    .game-type {
        display: flex;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        align-items: center;
        border-bottom: none;
        border-radius: 20px;
    }

    .course-list {
        width: 100%;
        height: 53px;
        -webkit-box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.75);
        border-radius: 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
    }
    
    .all-courses {
        display: flex;
        width: 25%;
        height: 100%;
        background-color: #ffffff;
        align-items: center;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 0;
    }
    
    .all-courses:hover {
        cursor: pointer;
    }
    
    .podpiska {
        display: flex;
        flex-direction: row;
        width: 75%;
        height: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
    }
    
    .podpiska1 {
        display: inline-flex;
        align-items: center;
        position: relative;
        width: 33.3%;
        height: 100%;
        border: none;
    }
    
    .podpiska1:hover {
        cursor: pointer;
    }
    
    .podpiska2:hover {
        cursor: pointer;
    }
    
    .podpiska3:hover {
        cursor: pointer;
    }
    
    .podpiska2 {
        display: inline-flex;
        align-items: center;
        position: relative;
        width: 33.3%;
        height: 100%;
        border-radius: none;
    }
    .podpiska3 {
        border: none;
        display: inline-flex;
        align-items: center;
        position: relative;
        width: 33.4%;
        height: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
    }
    
    .podpiska-text {
        position: relative;
        font-family: 'Open Sans';font-size: 16px;font-weight: lighter;color: #000000;
        text-align: center;
        margin-left: 0;
        width: 100%;
    }

    @media screen and (max-width: 540px) {
        .podpiska-text {
            position: relative;
            font-family: 'Open Sans';font-size: 14px;font-weight: lighter;color: #000000;
            text-align: center;
            margin-left: 0;
            width: 100%;
        }
    }

    @media screen and (max-width: 470px) {
        .podpiska-text {
            position: relative;
            font-family: 'Open Sans';font-size: 10px;font-weight: lighter;color: #000000;
            text-align: center;
            margin-left: 0;
            width: 100%;
        }
    }
    
    @media screen and (max-width:700px) {
        .theme-name {
            font-size: 13px;font-weight: normal;color: #282323;
        }
        
        .theme-info {
            font-size: 12px;font-weight: lighter;
        }
        
        .theme-puzzles {
            font-size: 12px;font-weight: lighter;
        }
    }

    @media screen and (min-width: 700px) {
        .theme-name {
            font-size: 17px;font-weight: normal;color: #282323;
        }
        
        .theme-info {
            font-size: 16px;font-weight: lighter;
        }
        
        .theme-puzzles {
            font-size: 16px;font-weight: lighter;
        }
    }

    .podpiskaImg {
        width: 0;
        margin-left: 0;
    }
    
    .setOn .podpiskaImg {
        margin-left: 0;
        width: 0;
        transform: rotate(-90deg);
    }

    .main-page {
        flex-direction: column;
        align-items: center;
    }

    .main-panel {
        margin-top: 26px;
        width: 90%; 
        max-width: 800px;
        margin-left: 0;
        margin-bottom: 26px;
    }
    
    .main-panel-content {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
        margin-bottom: 25px;
    }
    
    .levelName {
        width: 100%;
        text-align: center;
        font-size: 16px;font-weight: 550;color: #AB2A3B;
    }
    
    .pro-level {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .theme-block {
        width: 40%;
        height: fit-content;
        margin-right: 5%;
        margin-left: 5%;
    }
    
    .theme-content {
        background-color: rgb(255, 255, 255);
        box-shadow: 1px 1px 3px 3px rgba(71, 71, 71, 0.212);
        border-radius: 4%;
        width: 100%;   
        min-height: 100px;
        height: 100px;
    }
    
    .theme-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .themeImg {
        position: relative;
        z-index: 1;
        top: 20px;
        width: 80%;
        height: 80%;
        border-radius: 30px;
        overflow: hidden;
    }
    
    .themeImgSize {
        width: 100%;
        height: 100%;
    }

    .themeImgPoloska {
        position: relative;
        bottom: 47.5px;
        width: 105px;
        height: 26px;
        background-color: #fe1212;
    }

    .theme-name {
        margin-top: 28px;
        height: fit-content;
    }
    
    .theme-info {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        margin-top: 5px;
    }
    
    .theme-puzzles {
        margin-left: 0;
    }
    
    .theme-info span{
        color: #282323;
        text-wrap: nowrap;
    }
    
    .theme-info li{
        color: red;
        margin-right: 0;
        margin-left: 20px;
    }

}