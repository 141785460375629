.rating {
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rating-time-wrapper {
    z-index: 0;
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: 1000px;
    height: 100px;
    border-radius: 30px;
    -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    background-color: #FFFFFF;
}

.rating-time-item{
    height: 70%;
    width: 250px;
    margin-left: 60px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans';font-size: 22px;font-weight: 600;color: #AB2A3B;
}

.rating-time-item:hover{
    background-color: #AB2A3B;
    color: white;
}

.rating-time-item-active {
    background-color: #AB2A3B !important;
    color: white !important;
}

.rating-toplist-wrapper {
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    width: 1000px;
    min-height: 500px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    background-color: #FFFFFF;
}

.rating-toplist-wrapper table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    border-radius: 20px;
}

.rating-toplist-wrapper table thead td {
    background-color: #AB2A3B;
    font-family: 'Open Sans';font-size: 16px;font-weight: 600;color: #ffffff;
    height: 40px;
}

.rating-toplist-wrapper table thead td:first-child {
    border-top-left-radius: 20px;
}

.rating-toplist-wrapper table thead td:last-child {
    border-top-right-radius: 20px;
}

.rating-toplist-wrapper td:first-child {
    padding: 10px;
    width: 50px;
    text-align: center;
}

.rating-toplist-wrapper tbody tr {
    font-family: 'Open Sans';font-size: 16px;font-weight: 400;color: #000000;
}

.rating-toplist-wrapper tbody tr:nth-child(even) {
    background-color: #ab2a3b25;
}

.rating-toplist-wrapper tbody tr:last-child{
    border-top: 2px solid rgb(120, 120, 120);
    background-color: rgba(0, 255, 13, 0.125);
}

.rating-toplist-wrapper table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
}

.rating-toplist-wrapper table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
}
