.tile {
    display: grid;
    place-content: center;
    width: 57.4px;
    height: 57.4px;
}

.tile .chess-piece {
    width: 57.4px;
    height: 57.4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50px;
}

.tile .tile-highlight {
    z-index: 1;
}

.tile .chess-piece:hover {
    cursor: grab; 
}

.tile .chess-piece:active {
    cursor: grabbing;
}

.black-tile {
    background-color: #90695a;
}

.white-tile {
    background-color: #fff6f0;
}


.tile-highlight:not(.chess-piece-tile)::before {
    content: " ";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
}

.tile-highlight.chess-piece-tile::before {
    position: absolute;
    content: " ";
    width: 48px;
    height: 48px;
    border: 5px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;
}

#symbol {
    position: absolute;
    margin-top: 35px;
    margin-left: 47px;
    font-weight: 600;
    color: #ffffff;
}

#digit {
    position: absolute;
    margin-top: -1px;
    margin-left: 2px;
    font-weight: 600;
}

.black-tile > #digit {
    color: #fff6f0;
}

.white-tile > #digit {
    color: #90695a;
}

.black-tile > #symbol {
    color: #fff6f0;
}

.white-tile > #symbol {
    color: #90695a;
}

.rightMoveWhite1 {
    z-index: 0;
    width: 57.3px;
    height: 57.3px;
    background-color: rgba(165, 218, 253, 0.3);
}

.rightMoveWhite2 {
    z-index: 0;
    width: 57.3px;
    height: 57.3px;
    background-color: rgba(147, 218, 141, 0.5);
}

.rightMoveBlack1 {
    z-index: 0;
    width: 57.3px;
    height: 57.3px;
    background-color: rgb(143, 166, 183, 0.5);
}

.rightMoveBlack2 {
    z-index: 0;
    width: 57.3px;
    height: 57.3px;
    background-color: rgb(119, 163, 28, 0.5);
}

@media screen and (max-width: 1200px) {
    .tile {
        display: grid;
        place-content: center;
        width: 35px;
        height: 35px;
    }

    .tile .chess-piece {
        width: 35px;
        height: 35px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 30px;
    }

    .tile .chess-piece:hover {
        cursor: grab; 
    }

    .tile .chess-piece:active {
        cursor: grabbing;
    }

    .black-tile {
        background-color: #90695a;
    }

    .white-tile {
        background-color: #fff6f0;
    }


    .tile-highlight:not(.chess-piece-tile)::before {
        content: " ";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .tile-highlight.chess-piece-tile::before {
        position: absolute;
        content: " ";
        width: 25.5px;
        height: 25.5px;
        border: 5px solid rgba(0, 0, 0, 0.4);
        border-radius: 50%;
    }

    #symbol {
        position: absolute;
        margin-top: 23px;
        margin-left: 27px;
        font-weight: 600;
        font-size: 10px;
        color: #ffffff;
    }

    #digit {
        position: absolute;
        margin-top: -1px;
        margin-left: 2px;
        font-weight: 600;
        font-size: 10px;
    }

    .black-tile > #digit {
        color: #fff6f0;
    }

    .white-tile > #digit {
        color: #90695a;
    }

    .black-tile > #symbol {
        color: #fff6f0;
    }

    .white-tile > #symbol {
        color: #90695a;
    }

    .rightMoveWhite1 {
        z-index: 0;
        width: 35px;
        height: 35px;
        background-color: rgba(165, 218, 253, 0.3);
    }

    .rightMoveWhite2 {
        z-index: 0;
        width: 35px;
        height: 35px;
        background-color: rgba(147, 218, 141, 0.5);
    }

    .rightMoveBlack1 {
        z-index: 0;
        width: 35px;
        height: 35px;
        background-color: #90a7b8;
    }

    .rightMoveBlack2 {
        z-index: 0;
        width: 35px;
        height: 35px;
        background-color: #77a31c;
    }
}