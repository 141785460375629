.move-display-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    width: 200px;
    height: 100px;
    margin-top: 100px;
    margin-right: 50px;
    border: 1px solid #AB2A3B;
  }
  
  .move-display {
    margin-bottom: 20px;
    width: 100%;
  }
  
  .move-display h3 {
    margin: 0;
    font-size: 1.2em;
    color: #AB2A3B;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid #AB2A3B;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  .move-display p {
    margin: 0;
    font-size: 1.3em;
    font-weight: bold;
    color: #333;
    font-family: 'Courier New', Courier, monospace;
    padding: 8px;
    background: #f5f5f5;
    border-radius: 6px;
    transition: background 0.3s ease;
  }
  
  .move-display p:hover {
    background: #e0e0e0;
  }
  
  .best-move {
    color: #AB2A3B; /* Highlight the best move in a deep red */
  }
  
  .second-best-move {
    color: #6B1F2A; /* A darker shade of the main color for the second-best move */
  }
  