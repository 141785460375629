.profile {
    height: 1071px;
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-ramka {
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1000px;
    height: 950px;
    border-radius: 30px;
    -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    background-color: #FFFFFF;
}

.profile-upper-text {
    margin-top: 35px;
    font-family: 'Open Sans';font-size: 30px;font-weight: 600;color: #AB2A3B;
}

.profile-image-block {
    margin-top: 42px;
    width: 151px;
    height: 154px;
}

.profile-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 144px;
    height: 140px;
    border: 1px solid #AB2A3B;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.profile-image {
    width: 120%;
    height: 100%;
}

.edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
    margin-left: 75%;
    width: 1px;
    height: 1px;
}

.profile-score {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: 15px;
    font-family: 'Open Sans';font-size: 20px;font-weight: 400;
}

.profile-score img {
    width: 35px;
    margin-left: -5px;
    margin-top: 2px;
}

.profile-username {
    margin-top: 20px;
    width: 333px;
}

.profile-name {
    font-size: 20px;
}

.profile-inputs {
    width: 100%;
    height: 400px;
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
}

.profile-inputs-block {
    height: fit-content;
    width: 333px;
    margin-left: 105px;
    margin-top: 10px;
}

.profile-input {
    width: 333px;
    padding-left: 10px;
    height: 49px;
    background-color: transparent;
    border: 1px solid #B7B7B7;
    border-radius: 10px;
    font-family: 'Open Sans';font-size: 20px;font-weight: 400;
    margin-top: 5px;
}

.phone-containter {
    width: 348px !important;
    height: 52px;
    background-color: transparent;
    margin-top: 5px;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}

.phone-input {
    width: 100% !important;
    height: 100% !important;
    font-family: 'Open Sans';font-size: 20px;font-weight: 400;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.profile-country button {
    margin-top: 5px;
    border-radius: 10px;
    width: 348px !important;
    height: 52px !important;
}

.input-disabled {
    pointer-events: none;
}

.profile-button {
    width: 416px;
    height: 48px;
    border-radius: 10px;
    background-color: #AB2A3B;
    border: none;
    font-family: 'Open Sans';font-size: 20px;font-weight: 400;color: #FFFFFF;
    transition: all 300ms ease;
}

.profile-button:hover {
    background-color: #FFDF2C;
    transition: all 300ms ease;
    cursor: pointer;
}

.profile-logout {
    text-decoration: underline;
    margin-top: 25px;
    font-size: 20px;
    background-color: transparent;
    border: none;
}

.wrong-input{
    border: 2px solid rgba(255, 0, 0, 0.7) !important;
}

.profile-logout:hover {
    color: #AB2A3B;
    transition: all 300ms ease;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .profile {
        height: 1200px;
        width: 100vw;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .profile-ramka {
        z-index: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 800px;
        height: 1150px;
        border-radius: 30px;
        -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        background-color: #FFFFFF;
    }
    
    .profile-upper-text {
        margin-top: 40px;
        font-family: 'Open Sans';font-size: 25px;font-weight: 600;color: #AB2A3B;
    }
    
    .profile-image-block {
        margin-top: 30px;
        width: 131px;
        height: 134px;
    }
    
    .edit-button {
        margin-left: 120px;
    }
    
    .profile-name {
        font-size: 20px;
        align-self: baseline;
    }
    
    .profile-inputs {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
    }   

    .profile-username {
        margin-top: 20px;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
    }
    
    .phone-containter {
        width: 104% !important;
        max-width: 655px;
        height: 52px;
        background-color: transparent;
        margin-top: 5px;
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
        border-top-right-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
    }

    .profile-inputs-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        width: 80%;
        margin-left: 0;
        margin-top: 20px;
    }
    
    .profile-country {
        margin-top: 5px;
        border-radius: 10px;
        width: 104% !important;
        height: 52px !important;
    }

    .profile-country button {
        margin-top: 5px;
        border-radius: 10px;
        width: 100% !important;
        height: 52px !important;
    }

    .profile-input {
        width: 100%;
        padding-left: 10px;
        height: 49px;
        background-color: transparent;
        border: 1px solid #B7B7B7;
        border-radius: 10px;
        font-family: 'Open Sans';font-size: 20px;font-weight: 400;
        margin-top: 5px;
    }
    
    .profile-button {
        margin-top: 250px;
        width: 82%;
        height: 48px;
        border-radius: 10px;
        background-color: #AB2A3B;
        border: none;
        font-family: 'Open Sans';font-size: 20px;font-weight: 400;color: #FFFFFF;
        transition: all 300ms ease;
    }
    
    .profile-button:hover {
        background-color: #FFDF2C;
        transition: all 300ms ease;
        cursor: pointer;
    }
    
    .profile-logout {
        text-decoration: underline;
        margin-top: 20px;
        font-size: 20px;
        background-color: transparent;
        border: none;
    }
    
    .profile-logout:hover {
        color: #AB2A3B;
        transition: all 300ms ease;
        cursor: pointer;
    }
    
}