.app {
    height:fit-content;
    width: 100vw;
    max-width: 100%;
}

* {
    font-family: 'Open Sans', sans-serif;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.ornament-left {
    position: fixed;
    left: 0;
    z-index: -10;
    width: 500px;
}

.ornament-right {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -10;
    width: 500px;
}