.chess-page {
    width: 100vw;   
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.panel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1204px;
    margin-right: 100px;
}

.panel-spisok {
    margin-top: 75px;
    min-height: 710px;
}

.panel {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 800px;
    width: 800px;
    min-height: 650px;
    height: fit-content;
    background-color: rgb(255, 255, 255);
    box-shadow: 1px 1px 3px 3px rgba(71, 71, 71, 0.212);
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    float: left;
}

.panel-video {
    border-radius: 10px;
    height: fit-content;
}

.panel-size-button {
    align-self: flex-end;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.panel-size-button img {
    width: 20px;
    height: 20px;
}

.test {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 99.5%;
    height: 400px;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
}

.spisok {    
    position: relative;
    display: flex;
    flex-direction: column;
    left: 100px;
    width: 319px;
    height: 692px;
    background-color: rgb(255, 255, 255);
    box-shadow: 1px 1px 3px 3px rgba(71, 71, 71, 0.212);
    border-radius: 26px;
}

.topic {
    font-size: 20px;font-weight: bold;color: #AB2A3B;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75px;
    border-bottom: 1px solid #DD8A8A;
}

.spisokList {
    overflow-y: auto; /*TODO*/
}

.spisokList::-webkit-scrollbar {
    width: 3px;
    background: transparent;
}

.spisokList::-webkit-scrollbar-thumb {
    background: #B7B7B7;
    border-radius: 50px;
}


.zadachi {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    border-bottom: 1px solid #DD8A8A;
}

.zadachi:last-child {
    border-bottom: none;
}

.zadachi-content {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    min-height: 80px;
    width: 100%;
    cursor: pointer;
}

.block-spisokImg {
    width: 40px;
}

.spisokImg {
    position: relative;
    left: 5px;
    top: 10px;
    width: 29px;
}

.spisokImg-active {
    position: relative;
    top: 7px;
    left: 3px;
    width: 35px;
}

.zadachi-text {
    height: fit-content;
    width: 250px;
    text-align: center;
}

.id {
    font-size: 14px;font-weight: lighter;color: #c7c7c7;
    margin-top: 7px;
}

.title {
    font-size: 18px;font-weight: lighter;color: #282323;
    height: fit-content;
    margin-top: 2px;
    padding-bottom: 10px;
}

.zadachi-active {
    background-color: #AB2A3B;
}

.zadachi-active > .zadachi-content > .zadachi-text > .title {
    color: white;
}

.block-checkSign {
    position: relative;
    height: 100%;
    width: 20px;
    right: 7px;
    top: 1px;
}

.solved {
    position: relative;
    width: 40px;
}

.video-player {
    position: relative;
    width: 25px;
    margin-left: 10px;
}

.chess-win-scene {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 12;
}

.black {
    opacity: 0.5;
}

.hidden {
    display: none;
}

.spisok-lock {
    position: relative;
    right: 20px;
    top: 5px;
    width: 0px;
}

.spisok-lock-img {
    width: 20px;
}

.zadachi-test {
    width: 100%;
    padding-top: 5px;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.zadachi:last-child .zadachi-test {
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
}

.zadachi-active:last-child {
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 21px;
}

.zadachi-test-q {
    display: flex;
    margin-left: 30px;
    height: fit-content;
    margin-bottom: 10px;
    border-radius: 20px;
}

.activeQ {
    background-color: #AB2A3B;
}

.activeQ td {
    color: white !important;
}

.zadachi-test-r {
    margin-right: 10px;
}

input[type="radio"] {
    border: 1px solid black;
    margin-top: 8px;
}

.zadachi-test-t {
    font-size: 20px;font-weight: normal;color: #000000;

}

.zadachi-test-b {
    margin-top: 10px;
    align-self: center;
    width: 200px;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #AB2A3B;
    border: none;
    font-family: 'Open Sans';font-size: 17px;font-weight: 400;color: #FFFFFF;
    transition: all 300ms ease;
}

.zadachi-wrongAnswer {
    color: #AB2A3B;
    align-self: center;
}

.zadachi-test-b:hover {
    background-color: #FFDF2C;
    transition: all 300ms ease;
}

.progressBar {
    width: 1250px;
    height: 29px;
    margin-top: 39px;
    background-color: #E7E2E2;
    border-radius: 60px;
    text-align: center;
}

.progress-percentage {
    margin-top: 20px;
    font-size: 20px;font-weight: lighter;color: #AB2A3B;
}

.progress-line {
    border-radius: 60px;
    background-color: #AB2A3B;
    height: 100%;
    width: 0px;
}

.arrows {
    display: flex;
    justify-content: center;
    margin-right: 320px;
    margin-top: 10px;
    width: 170px;
    height: 52px;
    border: 1px solid #DD8A8A;
    border-radius: 60px;
    user-select: none; /* Standard */
}

.leftArrowWrap {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 52px;
    width: 65px;
    border-right: 1px solid #DD8A8A;
    user-select: none; /* Standard */
    cursor: pointer;
}

.rightArrowWrap {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 52px;
    width: 65px;
    user-select: none; /* Standard */
    cursor: pointer;
}

.arrow {
    height: 24px;
}

.zadachi-closed {
    background-color: #B7B7B7;
}

.sub-show {
    display: block;
}

.gameWithBot {
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1200px) {
    .chess-page {
        width: 100vw;   
        max-width: 100%;
        min-height: 1200px;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .panel-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-right: 0;
    }
    
    .panel-spisok {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        height: fit-content;
    }
    
    .panel {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 320px;
        width: 80%;
        min-height: 506px;
        height: fit-content;
        background-color: rgb(255, 255, 255);
        box-shadow: 1px 1px 3px 3px rgba(71, 71, 71, 0.212);
        border-radius: 20px;
        float: none;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .test {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 99.5%;
        height: 400px;
        background-color: rgb(255, 255, 255);
        border: 1px solid black;
    }
    
    .spisok {    
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        left: 0;
        width: 319px;
        height: 400px;
        background-color: rgb(255, 255, 255);
        box-shadow: 1px 1px 3px 3px rgba(71, 71, 71, 0.212);
        border-radius: 20px;
    }
    
    .topic {
        font-size: 20px;font-weight: bold;color: #AB2A3B;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 75px;
        border-bottom: 1px solid #DD8A8A;
    }
    
    .spisokList {
        overflow-y: auto;
    }
    
    .spisokList::-webkit-scrollbar {
        width: 4px;
        background: transparent;
    }
    
    .spisokList::-webkit-scrollbar-thumb {
        background: #B7B7B7;
        border-radius: 50px;
        height: 10px;
    }
    
    .zadachi {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        border-bottom: 1px solid #DD8A8A;
    }
    
    .zadachi-content {
        display: flex;
        flex-direction: row;
        min-height: 100px;
    }
    
    .block-spisokImg {
        width: 40px;
    }
    
    .spisokImg {
        position: relative;
        left: 5px;
        top: 10px;
        width: 29px;
    }
    
    .spisokImg-active {
        position: relative;
        top: 7px;
        left: 3px;
        width: 35px;
    }
    
    .zadachi-text {
        width: 250px;
        text-align: center;
    }
    
    .id {
        font-size: 14px;font-weight: lighter;color: #c7c7c7;
        margin-top: 7px;
    }
    
    .title {
        font-size: 18px;font-weight: lighter;color: #282323;
        padding-bottom: 15px;
        margin-top: 5px;
    }
    
    .zadachi-active {
        background-color: #AB2A3B;
    }
    
    .zadachi-active > .zadachi-content > .zadachi-text > .title {
        color: white;
    }
    
    .block-checkSign {
        position: relative;
        height: 100%;
        width: 20px;
        left: 5px;
        top: 5px;
    }
    
    .solved {
        position: relative;
        width: 20px;
        height: 20px;
    }
    
    .hidden {
        display: none;
    }
    
    .spisok-lock {
        position: relative;
        right: 20px;
        top: 5px;
        width: 0px;
    }
    
    .spisok-lock-img {
        width: 20px;
    }
    
    .zadachi-test {
        width: 100%;
        padding-top: 5px;
        height: fit-content;
        background-color: white;
        display: flex;
        flex-direction: column;
    }
    
    .zadachi-test-q {
        height: fit-content;
        display: flex;
        margin-left: 30px;
    }
    
    .zadachi-test-r {
        margin-right: 10px;
    }
    
    input[type="radio"] {
        border: 1px solid black;
    }
    
    .zadachi-test-t {
        font-size: 18px;font-weight: normal;color: #000000;
    }
    
    .zadachi-test-b {
        margin-top: 10px;
        align-self: center;
        width: 200px;
        height: 40px;
        margin-bottom: 20px;
        border-radius: 5px;
        background-color: #AB2A3B;
        border: none;
        font-family: 'Open Sans';font-size: 17px;font-weight: 400;color: #FFFFFF;
        transition: all 300ms ease;
    }
    
    .zadachi-wrongAnswer {
        color: #AB2A3B;
        align-self: center;
    }
    
    .zadachi-test-b:hover {
        background-color: #FFDF2C;
        transition: all 300ms ease;
    }
    
    .progressBar {
        width: 90%;
        height: 20px;
        border-radius: 5px;
        margin-top: 25px;
        background-color: #E7E2E2;
        border-radius: 60px;
        text-align: center;
    }
    
    .progress-percentage {
        margin-top: 10px;
        font-size: 16px;font-weight: lighter;color: #AB2A3B;
    }
    
    .progress-line {
        border-radius: 60px;
        background-color: #AB2A3B;
        height: 100%;
        width: 0px;
    }
    
    .arrows {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-right: 0px;
        width: 120px;
        height: 40px;
        border: 1px solid #DD8A8A;
        border-radius: 60px;

    }
    
    .leftArrowWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 60px;
        border-right: 1px solid #DD8A8A;
    }
    
    .rightArrowWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 60px;
    }
    
    .arrow {
        height: 18px;
    }
    
    .zadachi-closed {
        background-color: #B7B7B7;
    }
    
    .sub-show {
        display: block;
    }
        
    .gameWithBot {
        height: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

}