#chessboard {
    margin-top: 20px;
    margin-left: 20px;
    display: grid;
    grid-template-columns: repeat(8, 57.2px);
    grid-template-rows: repeat(8, 57.2px);
    width: 458px;
    height: 457.7px;
    background-color: rgb(255, 255, 255);
}

.chessboardWrapper2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    margin-top: 20px;
}

.chessboard-botGame {
    display: flex;
    flex-direction: row;
}

.chessboard-botGame-wTimer {
    display: flex;
    flex-direction: row;
    margin-right: 100px;
}

.chessboard-botGame-giveUpButton {
    margin-left: 10px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #AB2A3B;
    border: none;
    font-family: 'Open Sans';font-size: 16px;font-weight: 400;color: #FFFFFF;
    transition: all 300ms ease;
    width: 100px;
    height: 60px;
}

.chessboard-botGame-giveUpButton:hover {
    background-color: #ab2a3bd8;
    transition: all 300ms ease;
    cursor: pointer;
}

.arrow-canvas-botGame {
    position: absolute;
    top: 0;
    margin-top: 158px;
    margin-left: 20px;
    z-index: 1; /* Ensure it is above the chessboard */
    pointer-events: none; /* Allow pointer events to pass through */
}

.chessboard-board {
    width: 498px;
    height: 497.7px;
    background-color: #57340b;
}

.referee {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chessBoard-flipBoard {
    align-self: center;
    margin-top: 20px;
    width: 250px;
    height: 52px;
    border-radius: 10px;
    background-color: #AB2A3B;
    border: none;
    font-family: 'Open Sans';font-size: 20px;font-weight: 400;color: #FFFFFF;
    transition: all 300ms ease;
}

.chessBoard-flipBoard:hover {
    background-color: #FFDF2C;
    transition: all 300ms ease;
    cursor: pointer;
}

.turn {
    font-size: 20px;font-weight: normal;color: #282323;
    align-self: center;
    text-align: center;
    margin-top: 20px;
    width: 200px;
}

.lives {

    font-size: 20px;font-weight: normal;color: #282323;
    align-self: center;
    text-align: center;
    margin-top: 11px;
    width: 200px;
}

.task-name {
    margin-bottom: 20px;
    font-size: 30px;font-weight: bold;color: #AB2A3B;
    align-self: center;
    text-align: center;
}

.task-title {
    margin-bottom: 10px;
    font-size: 20px;font-weight: bold;color: #000000;
    align-self: center;
    text-align: center;
}

.task-title-rules {
    margin-top: 10px;
    font-size: 18px;font-weight: bold;color: #000000;
    align-self: center;
    text-align: center;
}

/* .task-description {
    margin-bottom: 47px;
    font-size: 30px;font-weight: normal;color: #282323;
    align-self: center;
    text-align: center;
} */

.move_symbol {
    margin-right: 8px;
    position: relative;
    top: 5px;
}

.lives span {
    font-size: 26px;font-weight: semibold;color: #AB2A3B;
    position: relative;
    top:3px
}

.referee .arrowsWrapper {
    height: 0px;
}

.referee .arrows {
    position: relative;
    left: 485px;
    top: 60px;
    height: 30px;
    width: 140px;
    border-width: 2px;
}

.referee .arrow {
    width: 10px;
}

.referee .leftArrowWrap .arrow {
    padding-left: 15px;
}

.referee .rightArrowWrap .arrow {
    padding-right: 50px;
}

.referee .leftArrowWrap {
    height: 30px;
    width: 70px;
    border-width: 2px;
}

.referee .rightArrowWrap {
    height: 30px;
    width: 70px;
}

@media screen and (max-width: 1200px) {
    #chessboard {
        margin-top: 20px;
        margin-left: 20px;
        display: grid;
        grid-template-columns: repeat(8, 35px);
        grid-template-rows: repeat(8, 35px);
        width: 280px;
        height: 280px;
        background-color: rgb(255, 255, 255);
    }
    
    .chessboardWrapper {
        display: flex;
        flex-direction: column;
    }
    
    .chessboard-board {
        width: 320px;
        height: 320px;
        background-color: #57340b;
    }
    
    .modal {
        position: absolute;
        z-index: 100;
        height: 280px;
        width: 280px;
    }
    
    .modal.hidden {
        display: none;
    }
    
    .modal > .modal-body {
        position: absolute;
        top: 5%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 150px;
        width: 280px;
        background-color: rgba(127, 127, 127, 0.8);
    }
    
    .modal > .modal-body > img {
        height: 40px;
        padding: 10px;
        border-radius: 50%;
    }
    
    .modal > .modal-body > img:hover {
        background-color: rgba(255, 0, 0, 0.3);
        cursor: pointer;
    }
    
    .modal > .modal-body > .checkmate-body {
        display: flex;
        flex-direction: column;
        gap: 48px;
    }
    
    .modal > .modal-body > .checkmate-body > span {
        font-size: 20px;
    }
    
    .modal > .modal-body > .checkmate-body > button {
        border: none;
        border-radius: 8px;
        padding: 10px 30px;
        font-size: 20px;
        color: white;
    }
    
    .modal > .modal-body > .checkmate-body > button:hover {
        cursor: pointer;
    }
    
    .modal-checkmate {
        position: absolute;
        z-index: 100;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
        width: 300px;
    }
    
    .modal.hidden-checkmate {
        display: none;
    }
    
    .modal-checkmate > .modal-body-checkmate {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: space-around;
        height: 150px;
        width: 300px;
        background-color: rgba(94, 94, 94, 0.8);
    }
    
    .modal-checkmate > .modal-body-checkmate > .checkmate-body-checkmate {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    .modal-checkmate > .modal-body-checkmate > .checkmate-body-checkmate > span {
        font-size: 20px;font-weight: normal;color: #ffffff;
    }
    
    .modal-checkmate > .modal-body-checkmate > .checkmate-body-checkmate > button {
        border: none;
        border-radius: 8px;
        align-self: center;
        padding: 0;
        width: 200px;
        height: 50px;
        font-size: 20px;
        background-color: #AB2A3B;
        color: #ffffff;
    }
    
    .modal-checkmate > .modal-body-checkmate > .checkmate-body-checkmate > button:hover {
        cursor: pointer;
    }

    .turn {
        font-size: 15px;font-weight: normal;color: #282323;
        align-self: center;
        text-align: center;
        margin-top: 10px;
        width: 100%;
    }
    
    .lives {
    
        font-size: 15px;font-weight: normal;color: #282323;
        align-self: center;
        text-align: center;
        margin-top: 11px;
        width: 100%;

    }
    
    .task-name {
        margin-bottom: 0;
        font-size: 20px;font-weight: bold;color: #AB2A3B;
        align-self: center;
        text-align: center;
    }
    
    .task-description {
        margin-bottom: 20px;
        font-size: 20px;font-weight: normal;color: #282323;
        align-self: center;
        text-align: center;
    }
    
    .move_symbol {
        margin-right: 8px;
        width: 15px;
        position: relative;
        top: 5px;
    }
    
    .lives span {
        font-size: 18px;font-weight: semibold;color: #AB2A3B;
        position: relative;
        top: 1px;
    }
}