.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    margin-top: 80px;
    background-color: #AB2A3B;
}

.footer-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 171px;
}

.footerImg {
    width: 56px;
}

.footer-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    font-family: 'Open Sans';font-size: 14px;font-weight: 550;color: #ffffff;
}

.footer-text-top {
    display: flex;
    flex-direction: row;
}

.footer-text-font {
    width: fit-content;
    text-align: center;
    margin-left: 10px;
}

.footer-text span:hover {
    color: #AB2A3B;
    background-color: #ffffff;
    border-radius: 10px;
    transition: 0.1s;
}

.footer-text span:hover a{
    color: #AB2A3B;
}

.footer-text span {
    padding: 8px;
    cursor: pointer;
}

.rights {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
}

.rights-text {
    font-family: 'Open Sans';font-size: 14px;font-weight: 400;color: #ffffff;
}

.footer a {
    -webkit-user-drag: none;
    text-decoration: none;
    color: #ffffff;
}

@media screen and (max-width: 1200px) {

    .footer {
        position: relative;
        bottom: 0px;
    }

    .rights {
        height: 60px;
    }

    .footer-text {
        margin-top: 15px;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: 'Open Sans';font-size: 14px;font-weight: 550;color: #AB2A3B;
    }

    .footer-text-top {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .footer-text-font {
        width: 150px;
    }

    .footer-text-font:nth-child(1) {
        margin-left: 20px;
    }
    
    .footer-text-font:nth-child(3) {
        margin-left: 30px;
    }
    

}