.support {
    position: fixed;
    z-index: 3;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(
        0,
        0,
        0,
        0.4
    );
}

.support-close {
    z-index: 4;
    position: absolute;
    height: 100%;
    width: 100%;
}

.support-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 4;
}

.support-ramka {
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    height: 560px;
    border-radius: 30px;
    -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    background-color: #FFFFFF;
}

.support-ramka-title {
    font-family: "Open Sans"; font-size: 30px; font-weight: 600; color:#000000;
    margin-top: 20px;
}

.support-form{
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.support-form label {
    margin-top: 10px;
}

.support-form input {
    height: 40px;
    width: 500px;
    margin-top: 5px;
    border-radius: 5px;
    padding: 5px;
    border: solid black 1px;
}

.support-incorrectBar {
    border-color: #DD8A8A;
}

.support-textArea {
    margin-top: 5px;
    padding: 5px;
    width: 500px;
    height: 100px;
    overflow: auto;
    resize: none;
    border-radius: 5px;
    font-family: "Open Sans"; font-size: 16px; font-weight: 400; color:#000000;
}

.support-form button{
    align-self: center;
    margin-top: 15px;
    width: 85%;
    height: 52px;
    border-radius: 10px;
    background-color: #AB2A3B;
    border: none;
    font-family: 'Open Sans';font-size: 20px;font-weight: 400;color: #FFFFFF;
    transition: all 300ms ease;
}

.support-form button:hover {
    background-color: #FFDF2C;
    transition: all 300ms ease;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .support {
        position: fixed;
        z-index: 3;
        width: 100vw;
        height: 100%;
        left: 0;
        top: 0;
        overflow: auto;
        background-color: rgba(
            0,
            0,
            0,
            0.4
        );
    }
    
    .support-block {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 4;
    }
    
    .support--close {
        z-index: 3;
        position: absolute;
        height: 100%;
        width: 100%;
    }
    
    .support-ramka {
        z-index: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        max-width: 600px;
        height: 580px;
        border-radius: 30px;
        -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        background-color: #FFFFFF;
    }

    .support-ramka-title {
        font-family: "Open Sans"; font-size: 20px; font-weight: 600; color:#000000;
        margin-top: 20px;
    }
    
    .support-form{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        height: fit-content;
    }
    
    .support-form label {
        margin-top: 10px;
    }
    
    .support-form input {
        height: 40px;
        width: 90%;
        margin-top: 5px;
        border-radius: 5px;
        padding: 5px;
        border: solid black 1px;
    }
    
    .support-incorrectBar {
        border-color: #DD8A8A;
    }
    
    .support-textArea {
        margin-top: 5px;
        padding: 5px;
        width: 90%;
        height: 100px;
        overflow: auto;
        resize: none;
        border-radius: 5px;
        font-family: "Open Sans"; font-size: 16px; font-weight: 400; color:#000000;
    }
    
    .support-form button{
        align-self: center;
        margin-top: 15px;
        width: 85%;
        height: 52px;
        border-radius: 10px;
        background-color: #AB2A3B;
        border: none;
        font-family: 'Open Sans';font-size: 20px;font-weight: 400;color: #FFFFFF;
        transition: all 300ms ease;
    }
    
    .support-form button:hover {
        background-color: #FFDF2C;
        transition: all 300ms ease;
        cursor: pointer;
    }
}