.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 100%;
    height: 87px;
    border-bottom: 1px solid #AB2A3B;
    background-color: #FFFFFF;
    opacity: 0;
    transform: translateY(-20px); /* Shift them up slightly */
    transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* Smooth transition */
    pointer-events: none;
}

.header.visible {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0); /* Move them to their normal position */
}

.headerImg {
    width: 56px;
    margin-right: 2%;
    z-index: 0;
    -webkit-user-drag: none;
}

.header-allCourse {
    width: fit-content;
    height: 22px;
    font-family: 'Open Sans';font-size: 16px;font-weight: 550;color: #000000;
    margin-right: 2%;
    -webkit-user-drag: none;
}

.header-web-site {
    min-width: 100px;
    width: fit-content;
    height: 22px;
    font-family: 'Open Sans';font-size: 16px;font-weight: 550;color: #AB2A3B;
    margin-right: 2%;
}

.header-subscribe {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 137px;
    height: 35px;
    background-color: #AB2A3B;
    font-family: 'Open Sans';font-size: 16px;font-weight: 550;color: #FFFFFF!important;
    border-radius: 30px;
    margin-right: 3%;
    -webkit-user-drag: none;
    cursor: pointer;
}

.header-search {
    display: inline-flex;
    align-items: center;
    border-radius: 30px;
    width: 293px;
    height: 35px;
    border: 1px solid #AB2A3B;
    margin-right: 8%;
    -webkit-user-drag: none;
}

.sHidden {
    visibility: hidden;
}

.header-loop {
    position: relative;
    left: 15px;
    width: 16px;
    height: 16px;
    -webkit-user-drag: none;
}

.header-searchBar {
    position: relative;
    padding-left: 20px;
    width: 100%;
    background-color: transparent;
    border: none;
    font-family: 'Open Sans';font-size: 16px;font-weight: 400;
    -webkit-user-drag: none;
}

.header-searchBar:focus {
    outline: none !important;
    border: none;
}

.header-poisk {
    background-color: transparent;
    border: none;
    margin-right: 25px;
    width: 50px;
    height: 30px;
    font-family: 'Open Sans';font-size: 16px;font-weight: 550;color: #AB2A3B;
    -webkit-user-drag: none;
}

.header-poisk:hover {
    cursor: pointer;
    text-decoration: underline;
}

.header-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 35px;
    background-color: #AB2A3B;
    font-family: 'Open Sans';font-size: 16px;font-weight: 550;color: #FFFFFF!important;
    border-radius: 30px;
    margin-right: 3%;
    -webkit-user-drag: none;
    cursor: pointer;
}

.header-reg {
    font-family: 'Open Sans';font-size: 16px;font-weight: 550;color: #AB2A3B;
    -webkit-user-drag: none;
}

.header-login {
    font-family: 'Open Sans';font-size: 16px;font-weight: 550;color: #AB2A3B;
    -webkit-user-drag: none;
    margin-left: 2%;
}

.header-username {
    margin-left: 4%;
    font-family: 'Open Sans';font-size: 16px;font-weight: 550;color: #AB2A3B;
}

.header-profile {
    margin-left: 10px;
    -webkit-user-drag: none;
}

.header-profile-image {
    width: 40px;
    height: 40px;
    border: 3px solid #AB2A3B;
    border-radius: 50%;
}

.header-lang {
    font-family: 'Open Sans';font-size: 16px;font-weight: 550;color: #AB2A3B;
    -webkit-user-drag: none;
    margin-left: 3%;
}

.header-lang select {
    font-family: 'Open Sans';font-size: 16px;font-weight: 550;color: #AB2A3B;
    border: none;
    cursor: pointer;
}

a {
    text-decoration: none;
}

.header a {
    -webkit-user-drag: none;
    color: #AB2A3B;
}

.header-subscribe a {
    -webkit-user-drag: none;
    color: #FFFFFF;
}

.header-rating a {
    -webkit-user-drag: none;
    color: #FFFFFF;
}

@media screen and (max-width: 1200px) {
    
    .headerImg {
        position: absolute;
        left: 30px;
        width: 56px;
        z-index: 0;
        -webkit-user-drag: none;
    }

    .header-lang {
        position: absolute;
        right: 80px;
        font-family: 'Open Sans';font-size: 16px;font-weight: 550;color: #AB2A3B;
        -webkit-user-drag: none;
    }

    .header-username {
        margin-left: 0;
        font-family: 'Open Sans';font-size: 14px;font-weight: 550;color: #AB2A3B;
    }

    .header-dropdown-menu a {
        font-family: 'Open Sans';font-size: 14px;font-weight: 300;color: #000000;
    }
    
    .menu-trigger img{
        position: absolute;
        right: 20px;
        top: 28px;
        width: 40px;
        overflow: hidden;
        background-color: #fff;
    }
    
    .header-dropdown-menu{
        position: absolute;
        top: 90px;
        right: 20px;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.05);;
        width: 160px;
        height: fit-content;
        z-index: 10;
    }
    
    .header-dropdown-menu.active{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: var(--speed) ease;
    }
    
    .header-dropdown-menu.inactive{
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: var(--speed) ease;
    }
    
    ul {
        padding-left: 0;
        margin-top: 0;
    }

    .header-dropdown-menu ul li{
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        width: 160px;
        height: 30px;
        display: flex;
        align-items: center;
        padding-left: 16px;
    }

    .header-dropdown-menu ul li:last-child{
        border-bottom: none;
    }
    
    .header-dropdownItem{
        display: flex;
    }
    
    .header-dropdownItem a{
        transition: var(--speed);
    }

}