.winPopup {
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(
        0,
        0,
        0,
        0.4
    );
}

.winPopup-close {
    z-index: 11;
    position: absolute;
    height: 100%;
    width: 100%;
}

.winPopup-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 11;
}

.winPopup-ramka {
    z-index: 11;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: 250px;
    border-radius: 30px;
    -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    background-color: #FFFFFF;
}

.winPopup-ramka img{
    margin-top: 10px;
    width: 130px !important;
    height: 130px !important;
}

.winPopup-ramka-title {
    font-family: "Open Sans"; font-size: 25px; font-weight: 600; color:#000000;
    margin-top: 10px;
}

.winPopup-ramka button{
    align-self: center;
    margin-top: 10px;
    width: 70%;
    height: 40px;
    border-radius: 10px;
    background-color: #AB2A3B;
    border: none;
    font-family: 'Open Sans';font-size: 15px;font-weight: 400;color: #FFFFFF;
    transition: all 300ms ease;
}

.winPopup-ramka button:hover {
    background-color: #FFDF2C;
    transition: all 300ms ease;
    cursor: pointer;
}